import React, { useState, useEffect } from "react";

import './Historic.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Svg_Close, Svg_Edit } from "services/SvgFile";

export default function PopUP_Historic(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ] = useState([]);

    function ClosePopUp(){
        setDataPage([]);
        SetModalState('Historic', false);
    }

    useEffect(()=>{
        RegisterModalData('Historic', setModaldata);
        RegisterModalObserver('Historic', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(modalData.list);            
        }
    }, [showPopUp]);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [dataPage]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all Historic">
                    <div className="div_data type_div">
                        <div className="title">
                            { modalData.title }
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th align="left">Título</th>
                                        <th width="50" align="right">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataPage.map((elem, index)=>{                                            
                                            return(
                                                <tr key={ index }>
                                                    <td align="center">{ index + 1 }</td>
                                                    <td align="left">{ elem.title }</td>
                                                    <td>
                                                        <div className="list_opt">
                                                            <div className="reactivate" onClick={ ()=>{ props.AltAccess(modalData.origin, modalData.type, "Reativar", elem.id, elem.title, 0); ClosePopUp(); }}>
                                                                Reativar
                                                            </div>
                                                            {
                                                                elem.currentPage === 'courses_classe_details' ?
                                                                    <div onClick={ ()=>{ props.OpenPage('currentPage', elem.currentPage); props.OpenPage('currentPageIdModule', elem.currentPageIdModule); props.OpenPage('currentPageIdClasse', elem.id); ClosePopUp(); } }>
                                                                        <Svg_Edit color="#324d6b" className="icons" />
                                                                    </div>
                                                                :
                                                                <div onClick={ ()=>{ props.OpenPage('currentPage', elem.currentPage); props.OpenPage('currentPageId', elem.id); ClosePopUp(); } }>
                                                                    <Svg_Edit color="#324d6b" className="icons" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
