import { useState, useEffect } from "react";

import './Details.css';

import ReactApexChart from "react-apexcharts";

import { GetDataPage } from "interface/Data";

import { colorIcon } from "fixedData";
import { Svg_Arrow, Svg_ArrowRight } from "services/SvgFile";

export default function Page_Details(props){

    const date = new Date();
    const [ showMonth, setShowMonth ] = useState(date.getMonth() + 1);
    const [ showYear, setShowYear ] = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);
    
    const [ series, setSeries ] = useState(ShowCurrentYear(currentYear));
    const [ options, setOptions ] = useState({
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        tooltip: {
            y: {
                formatter: (value) => `R$ ${ FormatPrice(value) }`,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: 'Financeiro - ' . currentYear,
            align: ''
        },
        colors:['#7c9f06', '#ff001c'],
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        }
    });

    function FormatPrice(value){
        if(value > 0){
            return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        }else {
            return "0,00";
        }
    }

    function ShowCurrentYear(value){        
        let janEntry = 0; let fevEntry = 0; let marEntry = 0; 
        let abrEntry = 0; let maiEntry = 0; let junEntry = 0; 
        let julEntry = 0; let agoEntry = 0; let setEntry = 0; 
        let outEntry = 0; let novEntry = 0; let dezEntry = 0;
        const showDataEntry = GetDataPage('entry').filter(item => item.payment_year == value);
        showDataEntry.map((elem, index)=>{
            if(elem.status['value'] == 1){
                switch (elem.payment_month) {
                    case 1:                  
                            janEntry += elem.price_graffic; 
                        break;
                    case 2: 
                            fevEntry += elem.price_graffic; 
                        break;
                    case 3: 
                            marEntry += elem.price_graffic; 
                        break;
                    case 4: 
                            abrEntry += elem.price_graffic; 
                        break;
                    case 5: 
                            maiEntry += elem.price_graffic; 
                        break;
                    case 6: 
                            junEntry += elem.price_graffic; 
                        break;
                    case 7: 
                            julEntry += elem.price_graffic; 
                        break;
                    case 8: 
                            agoEntry += elem.price_graffic; 
                        break;
                    case 9: 
                            setEntry += elem.price_graffic; 
                        break;
                    case 10:                        
                            outEntry += elem.price_graffic; 
                        break;
                    case 11: 
                            novEntry += elem.price_graffic; 
                        break;
                    case 12: 
                            dezEntry += elem.price_graffic; 
                        break;
                }
            }
        });       
        let dataEntry = [janEntry, fevEntry, marEntry, abrEntry, maiEntry, junEntry, julEntry, agoEntry, setEntry, outEntry, novEntry, dezEntry];
        
        let janExit = 0; let fevExit = 0; let marExit = 0; 
        let abrExit = 0; let maiExit = 0; let junExit = 0; 
        let julExit = 0; let agoExit = 0; let setExit = 0; 
        let outExit = 0; let novExit = 0; let dezExit = 0;
        
        const showDataExit = GetDataPage('exit').filter(item => item.payment_year == value);
        showDataExit.map((elem, index)=>{
            if(elem.status['value'] == 1){                
                switch (elem.payment_month) {
                    case 1: 
                            janExit += elem.price_graffic; 
                        break;
                    case 2: 
                            fevExit += elem.price_graffic; 
                        break;
                    case 3: 
                            marExit += elem.price_graffic; 
                        break;
                    case 4: 
                            abrExit += elem.price_graffic; 
                        break;
                    case 5: 
                            maiExit += elem.price_graffic; 
                        break;
                    case 6: 
                            junExit += elem.price_graffic; 
                        break;
                    case 7: 
                            julExit += elem.price_graffic; 
                        break;
                    case 8: 
                            agoExit += elem.price_graffic; 
                        break;
                    case 9: 
                            setExit += elem.price_graffic; 
                        break;
                    case 10:
                            outExit += elem.price_graffic; 
                        break;
                    case 11: 
                            novExit += elem.price_graffic; 
                        break;
                    case 12: 
                            dezExit += elem.price_graffic; 
                        break;
                }
            }
        });
        
        let dataExit = [janExit, fevExit, marExit, abrExit, maiExit, junExit, julExit, agoExit, setExit, outExit, novExit, dezExit];

        return [
            {
                name: "Entrada",
                data: dataEntry
            },
            {
                name: "Saída",
                data: dataExit
            }
        ];
    }

    useEffect(()=>{
        setSeries(ShowCurrentYear(currentYear));
    }, [currentYear]);

    return(
        <div className="div_inf_page inf_dashboard">
            <div className="div_show_year">
                <div className="arrow" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <Svg_ArrowRight className="icons left" color={ colorIcon } />
                </div>

                <div className="show_year">
                    { currentYear }
                </div>

                <div className="arrow" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <Svg_ArrowRight className="icons" color={ colorIcon } />
                </div>
            </div>

            <div className="list_data">
                <div className="div_inf_data" style={ { borderColor: colorIcon } }>
                    <div className="qtd">{ GetDataPage('courses').filter(item => item.status == 1).length }</div>
                    <div className="title" style={ { borderColor: colorIcon, background: colorIcon } }>
                        Qtd cursos
                    </div>
                </div>
                
                <div className="div_inf_data" style={ { borderColor: colorIcon } }>
                    <div className="qtd">{ GetDataPage('students').filter(item => item.status == 1).length }</div>
                    <div className="title" style={ { borderColor: colorIcon, background: colorIcon } }>
                        Qtd alunos
                    </div>
                </div>
            </div>
                        
            <div className="div_grafic">
                <ReactApexChart options={ options } series={ series } type="line" height={ 350 } />
            </div> 
           
        </div>
    )
}