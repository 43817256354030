import { useState, useEffect } from "react";

import './AltOrderList.css';

import { Svg_ArrowRight, Svg_Close } from "services/SvgFile";

export default function Comp_AltOrderList(props){

    const [ order, setOrder ] = useState(props.typeOrder);
    const [ typeOrder, setTypeOrder ] = useState('');

    function ReorderData(orderData) {
        setTypeOrder(orderData);
        const newList = [...props.list];
        if(props.label){
            newList.sort((a, b) => {                
                if(a[props.type]['label'] < b[props.type]['label']){
                    return orderData === 'asc' ? -1 : 1;
                }
    
                if(a[props.type]['label'] > b[props.type]['label']){
                    return orderData === 'asc' ? 1 : -1;
                }
            });

        }else {
            if(props.type2){
                newList.sort((a, b) => {                                   
                    if(a[props.type2]['label'] < b[props.type2]['label']){
                        return orderData === 'asc' ? -1 : 1;
                    }
        
                    if(a[props.type2]['label'] > b[props.type2]['label']){
                        return orderData === 'asc' ? 1 : -1;
                    }
                });

            }else {
                newList.sort((a, b) => {           
                    if(a[props.type] < b[props.type]){
                        return orderData === 'asc' ? -1 : 1;
                    }
        
                    if(a[props.type] > b[props.type]){
                        return orderData === 'asc' ? 1 : -1;
                    }
                });
            }
        }        
        props.setList(newList);
    }

    useEffect(()=>{
        setOrder(props.typeOrder);
    }, [props.typeOrder]);

    return(
        <div className="Comp_AltOrderList" onClick={ ()=>{ props.setTypeOrder(props.type); } }>
            <div data-tooltip-id="show_alert" data-tooltip-content="Ordem decrescente" data-tooltip-place="top" onClick={ ()=>{ ReorderData('desc'); } }>
                <Svg_ArrowRight className="icons icon_top" color={ order === props.type && typeOrder === 'desc' ? "#ADFA06" : "#666666" } />
            </div>
            <div data-tooltip-id="show_alert" data-tooltip-content="Ordem crescente" data-tooltip-place="top" onClick={ ()=>{ ReorderData('asc'); } }>
                <Svg_ArrowRight className="icons icon_bottom" color={ order === props.type && typeOrder === 'asc' ? "#ADFA06" : "#666666" } />
            </div>

            {
                order === props.type && typeOrder === 'desc' || order === props.type && typeOrder === 'asc' ?
                    <div className="" onClick={ ()=>{ props.setList(props.listOriginalData); setTypeOrder(''); props.setTypeOrder(''); } }>
                        <Svg_Close className="icons" color="#F00000" />
                    </div>
                : null
            }
        </div>
    )
}