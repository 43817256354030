
// Cookies folder project
// export const cookiesRegister = './';
// export const urlPhp = 'http://localhost/React_JS/fastevolution/josemery/php/';
// export const urlSite = 'http://localhost/React_JS/fastevolution/josemery/site/build/';

export const cookiesRegister = "https://dashboard.jrdespertardaconsciencia.com.br/";
export const urlPhp = "https://php.jrdespertardaconsciencia.com.br/";
export const urlSite = "https://jrdespertardaconsciencia.com.br/";

// Cookies name
export const typeCookie = 'josemery_dashboard';
export const typeCookiePage = 'josemery_page';
export const typeCookiePageId = 'josemery_page_id';
export const typeCookiePageIdModule = 'josemery_page_id_module';
export const typeCookiePageIdClasse = 'josemery_page_id_classe';

export const typeCookiePassw = 'josemery_passw';
export const typeCookieEmail = 'josemery_email';

export const colorIcon = '#2d3c53';
export const defaultColor = '#2d3c53';
export const colorIconSecondary = '#ffc400';

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'align', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "",
    toolbarAdaptive: false
}